import { Button, Card, Flex, Space, Text, Textarea } from '@mantine/core'
import { IconBinary } from '@tabler/icons-react'
import CryptoJS from 'crypto-js'
import { useState } from 'react'

export default function StringEncodeDecode() {
  const [str, setStr] = useState<string>('')
  return (
    <>
      <Flex justify="center" align="center" gap="xs">
        <IconBinary size={39} />
        <Text fw={700} size={34} lineClamp={1}>
          字符串编码解码
        </Text>
      </Flex>
      <Space h="xl" />
      <Card shadow="sm" withBorder>
        <Textarea
          placeholder="输入字符串"
          value={str}
          size="md"
          minRows={8}
          onChange={(text) => setStr(text.target.value)}
        />
        <Space h="md" />
        <Flex gap="md" wrap="wrap">
          <Button
            size="sm"
            onClick={() =>
              setStr(
                CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(str))
              )
            }
          >
            Base64 编码
          </Button>
          <Button
            size="sm"
            onClick={() => {
              setStr(
                CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(str))
              )
            }}
          >
            Base64 解码
          </Button>
          <Button
            size="sm"
            color="orange"
            onClick={() => {
              setStr(CryptoJS.MD5(str).toString())
            }}
          >
            MD5
          </Button>
          <Button
            size="sm"
            color="teal"
            onClick={() => {
              setStr(CryptoJS.SHA1(str).toString())
            }}
          >
            SHA1
          </Button>
          <Button
            size="sm"
            color="teal"
            onClick={() => {
              setStr(CryptoJS.SHA256(str).toString())
            }}
          >
            SHA256
          </Button>
          <Button
            size="sm"
            color="teal"
            onClick={() => {
              setStr(CryptoJS.SHA512(str).toString())
            }}
          >
            SHA512
          </Button>
        </Flex>
      </Card>
    </>
  )
}
