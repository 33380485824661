import { Card, Space, Text } from '@mantine/core'
import { Link } from 'react-router-dom'

import config from '../config.json'

export default function CardComponent(props: {
  name: string
  description: string
  route: string
}) {
  return (
    <Link to={props.route} style={{ color: 'inherit', textDecoration: 'none' }}>
      <Card shadow="sm" withBorder style={{ height: '100%' }}>
        <Text size="xl" weight={700} lineClamp={1}>
          {props.name}
        </Text>
        <Space h="xs" />
        <Text lineClamp={2}>{props.description}</Text>
        <Space h="xs" />
        <Text size="xs" color="grey" lineClamp={1}>
          {'https://' + config.domain + props.route}
        </Text>
      </Card>
    </Link>
  )
}
