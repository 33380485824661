import { LoadingOverlay, Space, TextInput } from '@mantine/core'
import { getHotkeyHandler } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons-react'
import { useRef, useState } from 'react'

import CardGroupComponent from '../components/CardGroupComponent'
import config from '../config.json'

export interface Card {
  name: string
  description: string
  route: string
}

export default function HomePage() {
  const [cards, setCards] = useState<Card[]>(
    config.pages
      .map((page) => {
        return {
          name: page.name,
          description: page.description,
          route: page.route,
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  )
  const [loading, setLoading] = useState(false)
  const searchInput = useRef<HTMLInputElement>(null)

  const filter = (keyword: string) => {
    setLoading(true)
    setCards(
      config.pages
        .filter((page) => {
          return (
            page.name.includes(keyword) || page.description.includes(keyword)
          )
        })
        .map((page) => {
          return {
            name: page.name,
            description: page.description,
            route: page.route,
          }
        })
    )
    setLoading(false)
  }

  return (
    <>
      <TextInput
        ref={searchInput}
        icon={<IconSearch size={16} />}
        placeholder="搜索"
        onChange={(kw) => filter(kw.target.value)}
        onKeyDown={getHotkeyHandler([
          ['Enter', () => filter(searchInput.current?.value || '')],
        ])}
      />
      <Space h="xl" />
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <CardGroupComponent cards={cards} />
    </>
  )
}
