import { AppShell, Container } from '@mantine/core'

import HeaderComponent from './components/HeaderComponent'

export default function Layout(props: { page: JSX.Element }) {
  return (
    <AppShell
      padding="md"
      header={<HeaderComponent />}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <Container size="xl">{props.page}</Container>
    </AppShell>
  )
}
