import { Grid } from '@mantine/core'

import CardComponent from './CardComponent'

export default function CardGroupComponent(props: {
  cards: { name: string; description: string; route: string }[]
}) {
  const cards = props.cards.map((card) => {
    return (
      <Grid.Col md={6} lg={3}>
        <CardComponent
          name={card.name}
          description={card.description}
          route={card.route}
        />
      </Grid.Col>
    )
  })
  return <Grid>{cards}</Grid>
}
