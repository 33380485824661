import { Flex, Header, Text } from '@mantine/core'
import { IconFlask } from '@tabler/icons-react'
import { Link } from 'react-router-dom'

import config from '../config.json'
import ColorSchemeToggle from './ColorSchemeToggle'

export default function HeaderComponent() {
  return (
    <Header height={55} p="xs">
      <Flex align="center" justify="space-between">
        <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
          <Flex align="center">
            <IconFlask />
            <Text size="xl" weight={700} lineClamp={1}>
              {config.name}
            </Text>
          </Flex>
        </Link>
        <ColorSchemeToggle />
      </Flex>
    </Header>
  )
}
