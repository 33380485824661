import {
  ActionIcon,
  Avatar,
  Card,
  Flex,
  Grid,
  LoadingOverlay,
  Select,
  Space,
  Table,
  Text,
  TextInput,
} from '@mantine/core'
import { getHotkeyHandler } from '@mantine/hooks'
import {
  IconAlignLeft,
  IconDisc,
  IconMusic,
  IconSearch,
} from '@tabler/icons-react'
import axios from 'axios'
import { useRef, useState } from 'react'

export default function MusicURLRetrieve() {
  const [source, setSource] = useState<string>('')
  const [keyword, setKeyword] = useState<string>('')
  const [result, setResult] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const searchInput = useRef<HTMLInputElement>(null)

  const apiServer =
    process.env.NODE_ENV === 'production'
      ? 'https://api-v2.hans362.cn/vip'
      : 'https://api-v2.hans362.cn'

  const query = async (source: string, keyword: string) => {
    setLoading(true)
    setSource(source)
    setKeyword(keyword)
    if (!source || !keyword) {
      setResult([])
    } else {
      const { data } = await axios.get(
        apiServer + '/?server=' + source + '&type=search&id=' + keyword
      )
      setResult(data)
    }
    setLoading(false)
  }

  const tbody = result.map((item: any) => {
    return (
      <tr>
        <td>
          <Avatar src={item.pic}>
            <IconDisc />
          </Avatar>
        </td>
        <td>{item.title.replaceAll('&nbsp;', ' ')}</td>
        <td>{item.author.replaceAll('nbsp;', ' ')}</td>
        <td>
          <Flex align="center" gap="xs">
            <ActionIcon
              variant="default"
              component="a"
              target="_blank"
              href={item.url}
            >
              <IconMusic size={16} />
            </ActionIcon>
            <ActionIcon
              variant="default"
              component="a"
              target="_blank"
              href={item.lrc}
            >
              <IconAlignLeft size={16} />
            </ActionIcon>
          </Flex>
        </td>
      </tr>
    )
  })

  return (
    <>
      <Flex justify="center" align="center" gap="xs">
        <IconMusic size={39} />
        <Text fw={700} size={34} lineClamp={1}>
          音乐直链解析
        </Text>
      </Flex>
      <Space h="xl" />
      <Card shadow="sm" withBorder>
        <Grid>
          <Grid.Col xs={3} md={2}>
            <Select
              placeholder="选择音源"
              data={[
                { value: 'netease', label: '网易云音乐' },
                { value: 'kugou', label: '酷狗音乐' },
                { value: 'kuwo', label: '酷我音乐' },
                { value: 'tencent', label: 'QQ音乐' },
                { value: 'xiami', label: '虾米音乐' },
                { value: 'baidu', label: '百度音乐' },
              ]}
              onChange={(src) => query(src ? src : 'netease', keyword)}
              withinPortal
            />
          </Grid.Col>
          <Grid.Col span="auto">
            <TextInput
              ref={searchInput}
              icon={<IconSearch size={16} />}
              placeholder="搜索音乐 / 歌手"
              onChange={(kw) => query(source, kw.target.value)}
              onKeyDown={getHotkeyHandler([
                [
                  'Enter',
                  () => query(source, searchInput.current?.value || ''),
                ],
              ])}
            />
          </Grid.Col>
        </Grid>
      </Card>
      <Space h="xl" />
      <Card shadow="sm" style={{ overflowX: 'auto' }} withBorder>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Table highlightOnHover>
          <thead>
            <tr>
              <th></th>
              <th>名称</th>
              <th>歌手</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{tbody}</tbody>
        </Table>
      </Card>
    </>
  )
}
