import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import App from './App'
import './index.css'
import HomePage from './pages/HomePage'
import MusicURLRetrieve from './pages/MusicURLRetrieve'
import StringEncodeDecode from './pages/StringEncodeDecode'
import UnlimitedCloudStorage from './pages/UnlimitedCloudStorage'
import reportWebVitals from './reportWebVitals'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App page={<HomePage />} />,
  },
  {
    path: '/music-url-retrieve',
    element: <App page={<MusicURLRetrieve />} />,
  },
  {
    path: '/unlimited-cloud-storage',
    element: <App page={<UnlimitedCloudStorage />} />,
  },
  {
    path: '/string-encode-decode',
    element: <App page={<StringEncodeDecode />} />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
