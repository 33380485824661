import { Anchor, Card, Flex, Group, List, Space, Text } from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
import { Prism } from '@mantine/prism'
import { IconCloudUpload, IconUpload } from '@tabler/icons-react'
import { useState } from 'react'

import config from '../config.json'

export default function UnlimitedCloudStorage() {
  const [downloadLink, setDownloadLink] = useState('')
  const [loading, setLoading] = useState(false)

  const upload = async (file: File) => {
    setLoading(true)
    const { size } = file
    const ONES = []
    for (let b = 0x00; b <= 0xff; ++b) {
      let cnt = 0
      for (let s = 0; s < 8; ++s) {
        if ((b >> s) % 2 === 1) {
          ++cnt
        }
      }
      ONES.push(cnt)
    }
    let cnt = 0
    const reader = file.stream().getReader()
    while (true) {
      const { done, value: chunk } = await reader.read()
      if (done) {
        break
      }
      for (let i = 0; i < chunk.length; i++) {
        cnt += ONES[chunk[i]]
      }
    }
    setDownloadLink(
      `https://${config.domain}/api/unlimitedCloudStorage/${
        size * 8 - cnt + 114514
      }/${cnt + 1919810}/${file.name}`
    )
    setLoading(false)
  }

  return (
    <>
      <Flex justify="center" align="center" gap="xs">
        <IconCloudUpload size={39} />
        <Text fw={700} size={34} lineClamp={1}>
          无限容量云存储
        </Text>
      </Flex>
      <Space h="xl" />
      <Card shadow="sm" withBorder>
        <Text>即刻获取无限容量云存储服务，Hans362 's Lab 承诺：</Text>
        <Space h="xs" />
        <List>
          <List.Item>无限容量</List.Item>
          <List.Item>极速传输</List.Item>
          <List.Item>支持任何文件类型</List.Item>
          <List.Item>不会丢失您文件的任何一个比特</List.Item>
        </List>
        <Space h="xs" />
        <Text td="line-through">
          当然，天下没有免费的午餐，至于代价...需要你自己去探索哦（逃）
        </Text>
        <Space h="xs" />
        <Text size="xs">
          * 本服务使用的存储技术由{' '}
          <Anchor
            href="https://yoursunny.com/p/summer-host/storage/"
            target="_blank"
          >
            Deep Atlantic Storage
          </Anchor>{' '}
          最先提出，并非 Hans362 's Lab 原创。
        </Text>
      </Card>
      <Space h="xl" />
      <Card shadow="sm" withBorder>
        <Dropzone
          onDrop={(files) => upload(files[0])}
          maxFiles={1}
          loading={loading}
          multiple={false}
        >
          <Group
            position="center"
            spacing="xl"
            style={{ minHeight: 220, pointerEvents: 'none' }}
          >
            <Dropzone.Idle>
              <IconUpload size={50} stroke={1.5} />
            </Dropzone.Idle>
            <Text size="xl" inline>
              上传文件
            </Text>
          </Group>
        </Dropzone>
        {downloadLink ? (
          <>
            <Space h="xl" />
            <Text>上传成功！请妥善保管此链接：</Text>
            <Space h="xs" />
            <Prism language="markdown" copyLabel="复制" copiedLabel="已复制！">
              {downloadLink}
            </Prism>
          </>
        ) : null}
      </Card>
    </>
  )
}
